import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from '../../hooks'
import { useAvaxDogsContract } from '../../hooks/useContract'
import { AVALANCHE_DOGS_REBORN_ADDRESS } from '../../config'

const ONE_AVAX = BigNumber.from('1000000000000000000')

const ClaimButton = styled(Button)`
  background: #FFFFFF;
  border-radius: 40px;
  border-width: thick;
  border-color: #555150;
  width: 135px;
  &:hover {
    background-color: #818181;
    border-color: #555150;
  }
  &:disabled {
    background-color: #818181;
    border-color: #555150;
  }
  &:focus {
    background-color: #818181;
    border-color: #555150;
  }
`

const ButtonText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  text-transform: capitalize;
  color: #555150;
`

const RewardsLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #555150;
  font-family: MontSerrat;
  font-style: normal;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 40px;
  color: #FFFFFF;
  width: 218px;
`

export default function RewardsBar() {
  const { account } = useActiveWeb3React()
  const [ rewards, setRewards ] = useState<BigNumber>(BigNumber.from(0))
  const avaxDogsContract = useAvaxDogsContract(AVALANCHE_DOGS_REBORN_ADDRESS, true)

  useEffect(() => {
   avaxDogsContract?.getReflectionBalances()
    .then((response: any) => {
      setRewards(response)
    })
    .catch((error: any) => {
      console.log(error)
    })
  })
  const handleClaim = () => {
    avaxDogsContract?.claimRewards()
    .then((response: any) => {
      console.log(response)
    })
    .catch((error: any) => {
      console.log(error)
    })
  }

  return (
    <Container>
      <Row>
        <RewardsLabel>
          {(rewards.mul(BigNumber.from(10000)).div(ONE_AVAX).toNumber() / 10000).toFixed(4)} AVAX
        </RewardsLabel>
        <div style={{width: "50px"}}/>
        <ClaimButton
          disabled={rewards.isZero()}
          onClick={handleClaim}>
            <ButtonText>Claim</ButtonText>
        </ClaimButton>
      </Row>
    </Container>
  );
}
