import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React from 'react'
import { Activity } from 'react-feather'
import styled from 'styled-components'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkContextName } from '../../constants'
import { useWalletModalToggle } from '../../state/application/hooks'
import { injected } from '../../connectors'
import Button from 'react-bootstrap/Button'

import WalletModal from '../WalletModal'

const AddressRed = styled.div`
  font-family: Space Mono;
  font-style: normal;
  font-size: 23px;
  font-weight: 400;
  line-height: 34px;
  color: #FF4247;
`

const ButtonText = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
`

const Web3StatusGeneric = styled(Button)`
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  margin-top: 5%;
  height: 10%;
  width: 50%;
  font-family: MontSerrat;
  font-style: normal;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  background: #FF4141;
  border-radius: 40px;
  border: none;
  &:hover {
    background-color: #818181;
  }
`

const Web3StatusConnect = styled(Button)`
  margin-top: 5%;
  height: 10%;
  font-family: MontSerrat;
  font-style: normal;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  background: #FF4141;
  border-radius: 40px;
  border: none;
  &:hover {
    background-color: #818181;
  }
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

function switchToAvalanche() {
  const AVALANCHE_MAINNET_PARAMS = {
    chainId: '0xa86a',
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network/']
  }

  injected.getProvider().then(provider => {
    provider
      .request({
        method: 'wallet_addEthereumChain',
        params: [AVALANCHE_MAINNET_PARAMS]
      })
      .catch((error: any) => {
        console.log(error)
      })
  })
}

function Web3StatusInner() {
  const { active, account, connector, activate, error } = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()

  const tryActivation = async () => {
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }

    activate(connector ? connector : injected, undefined, true).catch(error => {
      if (error instanceof UnsupportedChainIdError) {
        activate(connector ? connector : injected)
      } else {
        console.log(error)
      }
    })
  }


  if (account) {
    return (
      <AddressRed>{account}</AddressRed>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={switchToAvalanche}>
        <ButtonText>{error instanceof UnsupportedChainIdError ? 'Switch to Avalanche network' : 'Error'}</ButtonText>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={tryActivation}>
        <ButtonText>Connect to Metamask wallet</ButtonText>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal/>
    </>
  )
}
