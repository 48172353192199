import React, { useState, useMemo, useEffect } from 'react'
import { Route } from 'react-router-dom'
import axios from 'axios'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Countdown from 'react-countdown'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { useActiveWeb3React } from './hooks'
import Web3ReactManager from './components/Web3ReactManager'
import styled from 'styled-components'
import { BigNumber } from '@ethersproject/bignumber'
import './App.css'
import FlexLayout from './components/Layout/Flex'
import DogCard from './components/DogCard'
import ShibavaxHeader from './components/Header'
import Web3Status from './components/Web3Status'
import MintBar from './components/MintBar'
import RewardsBar from './components/RewardsBar'
import SwapBar, { MAX_SWAP_PER_TX } from './components/SwapBar'
import { AVALANCHE_DOGS_ADDRESS, AVALANCHE_DOGS_REBORN_ADDRESS, AVALANCHE_DOGS_MAX_MINT_NFTS } from './config'
import Banner from './assets/avaxdogs-banner.png'
import Bone from './assets/bone.png'
import Medal from './assets/medal.png'
import Kalao from './assets/kalao.png'
import { useAvaxDogsContract } from './hooks/useContract'
import { NEVER_RELOAD, useSingleCallResult } from './state/multicall/hooks'
import { useWalletModalToggle } from './state/application/hooks'

const ONE_AVAX = BigNumber.from('1000000000000000000')

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const Header = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
  text-transform: capitalize;
  color: #818181;
`

const Regular = styled.div`
  font-family: MontSerrat;
  font-style: normal;
  font-size: 23px;
  font-weight: 400;
  line-height: 34px;
  color: #000000;
`

const RegularGrey = styled.div`
  font-family: MontSerrat;
  font-style: normal;
  font-size: 45px;
  font-weight: 800;
  color: #818181;
`

const RegularRedBold = styled(Regular)`
  margin-top: 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  color: #FF4D4D;
`

const ErrorText = styled(Regular)`
  margin-top: 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FF4D4D;
`

const SecondaryMarketHeader1 = styled.div`
  margin-top: 5%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
`

const SecondaryMarketHeader2 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
`

const SecondaryMarketDivider = styled.hr`
  margin-top: 5%;
  margin-bottom: 15%;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  border-top: 1px rounded #FFFFFF;
  border-radius: 5px;
  background-color: #FFFFFF;
`

const SecondaryMarketText = styled.div`
  margin-top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  text-align: left;
  margin-left: 20px;
  color: #FFFFFF;
`

const TokenomicsHeader = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 68px;
  text-align: center;
  text-transform: uppercase;
  color: #FF4141;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #CBC8C9;
`

const TokenomicsText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: left;
  margin-left: 20px;
  color: #818181;
`

const TokenomicsRow = styled(Row)`
  align-items: stretch;
  margin-top: 20px;
  flex-wrap: nowrap;
`

const WalletDivider = styled.hr`
  margin-top: 5%;
  margin-bottom: 10%;
  margin-right: 25%;
  width: 75%;
  border-top: 1px solid #CBC8C9;
`

const VerticalTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`

const AppWrapper = styled(Container)`
  margin-top: 40px;
`

const CountDownHeader = styled.div`
  top: 5%;
  position: relative;
  font-family: MontSerrat;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  color: #C4C4C4;
`

const CountDownToLaunch = styled(Countdown)`
  top: 5%;
  position: relative;
  font-family: MontSerrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  text-transform: capitalize;
  color: #FF4247;
`

const KalaoLink = styled.a`
  font-family: Montserrat;
  font-style: normal;
  color: #003262
`

type TokenInfo = {
  id: number
  name: string
  image: string
}

const App = () => {
  const { account } = useActiveWeb3React()
  const [ error, setError ] = useState<string>("")
  const [ saleStarted, setSaleStarted ] = useState(false)

  const avaxDogsContractSigner = useAvaxDogsContract(AVALANCHE_DOGS_ADDRESS, true)
  const avaxDogsRebornContract = useAvaxDogsContract(AVALANCHE_DOGS_REBORN_ADDRESS, true)
  const avaxDogsRebornContractSigner = useAvaxDogsContract(AVALANCHE_DOGS_REBORN_ADDRESS, true)

  const maxNftsCall = useSingleCallResult(avaxDogsRebornContract, 'MAX_NFTS', undefined, NEVER_RELOAD)
  const maxNfts = useMemo(() => {
    if (maxNftsCall.loading) return 10000
    if (maxNftsCall.result) return maxNftsCall.result[0]
    return 10000
  }, [maxNftsCall])

  const [ mintedNfts, setMintedNfts ] = useState<BigNumber>(BigNumber.from(0))
  const mintedNftsCall = useSingleCallResult(avaxDogsRebornContract, 'totalSupply')
  useEffect(() => {
    if (!mintedNftsCall.loading && mintedNftsCall.result) {
      setMintedNfts(mintedNftsCall.result[0])
    }
  }, [mintedNftsCall])

  const nftPriceCall = useSingleCallResult(avaxDogsRebornContract, 'NFT_PRICE', undefined, NEVER_RELOAD)
  const nftPrice = useMemo(() => {
    if (nftPriceCall.loading) return BigNumber.from(25).mul(ONE_AVAX).div(10)
    if (nftPriceCall.result) return nftPriceCall.result[0]
    return BigNumber.from(25).mul(ONE_AVAX).div(10)
  }, [nftPriceCall])

  const saleStartedCall = useSingleCallResult(avaxDogsRebornContract, 'hasSaleStarted')
  useEffect(() => {
    if (!saleStartedCall.loading && saleStartedCall.result) {
      setSaleStarted(saleStartedCall.result[0])
    }
  }, [saleStartedCall])

  const handleMint = (numToMint: number) => {
    setError("")
    if (numToMint > 0 && numToMint <= AVALANCHE_DOGS_MAX_MINT_NFTS) {
      const totalPrice = BigNumber.from(nftPrice).mul(numToMint)
      avaxDogsRebornContractSigner?.mintNFT(numToMint, {
        from: account,
        value: totalPrice
      })
      .catch((error: any) => {
        console.log(error)
        if (error.code === -32603 && error.data.code === -32000) {
          setError('Insufficient funds')
        }
      })
    }
  }

  const [ oldTokens, setOldTokens ] = useState<TokenInfo[]>([])
  useEffect(() => {
    setOldTokens([])
    avaxDogsContractSigner?.tokensOfOwner(account)
      .then((response: any) => {
        response?.map(async (i: BigNumber) => {
          const uri = await avaxDogsContractSigner?.tokenURI(i)
          const resp = await axios.get(uri.startsWith("ipfs://") ?
            uri.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/") : uri)
          const image = !resp.data.image.startsWith("ipfs://") ? resp.data.image
            : resp.data.image.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
          setOldTokens(prevTokens => [...prevTokens, {
            id: i.toNumber(),
            name: resp.data.name,
            image: image
          }])
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [account, avaxDogsContractSigner])

  const [ tokens, setTokens ] = useState<TokenInfo[]>([])
  useEffect(() => {
    setTokens([])
    avaxDogsRebornContractSigner?.tokensOfOwner(account)
      .then((response: any) => {
        response?.map(async (i: BigNumber) => {
          const uri = await avaxDogsRebornContractSigner?.tokenURI(i)
          const resp = await axios.get(uri.startsWith("ipfs://") ?
            uri.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/") : uri)
          const image = !resp.data.image.startsWith("ipfs://") ? resp.data.image
            : resp.data.image.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
          setTokens(prevTokens => [...prevTokens, {
            id: i.toNumber(),
            name: resp.data.name,
            image: image
          }])
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [account, avaxDogsRebornContractSigner])

  const toggleWalletModal = useWalletModalToggle()

  return (
    <>
      <Route component={GoogleAnalyticsReporter} />
      <ShibavaxHeader/>
      <AppWrapper>
        <Web3ReactManager>
          <Container>
            <Row>
              <img src={Banner} width='1228px' alt="avaxdogs banner"/>
            </Row>
            <Row style={{marginTop: "5%"}}>
              <Col sm={8}>
                <Header>Avalanche Dogs minted</Header>
                <RegularRedBold>{mintedNfts.isZero() ? "?" : mintedNfts.toString()}/{maxNfts.toString()}</RegularRedBold>
                <WalletDivider/>
                <RegularGrey>Your wallet address:</RegularGrey>
                <Web3Status/>
                <RegularGrey style={{marginTop: "5%", marginBottom: "5%"}}>
                  Amount of NFTs to mint (max 10):
                </RegularGrey>
                <MintBar
                  saleStarted={saleStarted}
                  nftPrice={nftPrice}
                  handleMint={handleMint} />
                {error && (
                  <ErrorText>{error}</ErrorText>
                )}
                <RegularGrey style={{marginTop: "5%", marginBottom: "5%"}}>
                  Your rewards to claim:
                </RegularGrey>
                <RewardsBar/>
              </Col>
              <Col sm={3}>
                <img src={Medal} alt="daft dog medal"/>
              </Col>
            </Row>
            <Row>
              <TokenomicsHeader>Tokenomics</TokenomicsHeader>
            </Row>
            <Row>
              <Col sm={7} style={{marginRight: "10px"}}>
                <TokenomicsRow>
                  <VerticalTop><img src={Bone} alt="bone"/></VerticalTop>
                  <TokenomicsText>
                    <b>10,000 unique NFTs</b> at the cost of <b>2.5 AVAX each</b>.
                  </TokenomicsText>
                </TokenomicsRow>
                <TokenomicsRow>
                  <VerticalTop><img src={Bone} alt="bone"/></VerticalTop>
                  <TokenomicsText>
                    <b>40%</b> of the NFT's total sale redistributed to the community.
                  </TokenomicsText>
                </TokenomicsRow>
                <TokenomicsRow>
                  <VerticalTop><img src={Bone} alt="bone"/></VerticalTop>
                  <TokenomicsText>
                    <b>5,000 AVAX</b> will be used for a buyback of $SHIBX and
                    redistributed to holders of <b>500 rare NFTs</b>.
                  </TokenomicsText>
                </TokenomicsRow>
                <TokenomicsRow>
                  <VerticalTop><img src={Bone} alt="bone"/></VerticalTop>
                  <TokenomicsText>
                    <b>5,000 AVAX</b> will be used to add liquidity to the
                    AVAX/SHIBX pair (on Baguette DEX) and the LP tokens will
                    be burned right after.
                  </TokenomicsText>
                </TokenomicsRow>
                <Row style={{marginTop: "10%"}}>
                  <TokenomicsText>
                    To take advantage of all the features of our secondary market,
                    list your AVAX Dogs NFTs now on <KalaoLink href="https://marketplace.kalao.io/collection/0x2cca3a1a45c1b1036d7194cd15a981b8c2f9dee4" target="_blank">kalao.io</KalaoLink>.
                  </TokenomicsText>
                </Row>
                <Row style={{marginTop: "10%"}}>
                  <img src={Kalao} alt="Kalao logo"/>
                </Row>
              </Col>
              <Col sm={4} style={{backgroundColor: "#FF4141", borderRadius: "25px"}}>
                <SecondaryMarketHeader1>KALAO</SecondaryMarketHeader1>
                <SecondaryMarketHeader2>SECONDARY MARKET</SecondaryMarketHeader2>
                <SecondaryMarketDivider/>
                <SecondaryMarketText>
                  <p> • 6% tax on each secondary sale</p><br/>
                  <p> • 3% will go to holders of Avalanche Dogs NFTs.</p><br/>
                  <p> • 3% will be used to buyback $SHIBX to add liquidity on the AVAX/SHIBX pair then the LP tokens will be burned.</p><br/>
                </SecondaryMarketText>
              </Col>
            </Row>
            {oldTokens.length > 0 && (
              <>
                <Row>
                  <TokenomicsHeader>My Dogs (old contract)</TokenomicsHeader>
                </Row>
                <Row style={{marginBottom: "5%"}}>
                  <TokenomicsText>
                    These dogs belong to the old deprecated contract, approve then click the swap button
                    to move them to the new contract.
                    Note that you cannot swap more than {MAX_SWAP_PER_TX} dogs per transaction.
                  </TokenomicsText>
                </Row>
                <SwapBar/>
                {oldTokens.length > 0 && (
                  <Row style={{justifyContent: "center", marginTop: "5%"}}>
                    <CardLayout>
                      {oldTokens.sort((it1, it2) => {
                          if (it1.name < it2.name) {
                            return -1
                          } else if (it1.name > it2.name) {
                            return 1
                          } else {
                            return 0
                          }
                        }).map((token) => (
                          <DogCard id={token.id} name={token.name} image={token.image} />
                        )
                      )}
                    </CardLayout>
                  </Row>
                )}
              </>
            )}
            <Row>
              <TokenomicsHeader>My Dogs</TokenomicsHeader>
            </Row>
            <Row style={{justifyContent: "center"}}>
              {tokens.length === 0 && (
                <TokenomicsText style={{marginBottom: "30%"}}>
                  You don't have any Avalanche Dogs yet, go mint yourself a few cool buddies...
                </TokenomicsText>
              )}
              {tokens.length > 0 && (
                <CardLayout>
                  {tokens.sort((it1, it2) => {
                      if (it1.name < it2.name) {
                        return -1
                      } else if (it1.name > it2.name) {
                        return 1
                      } else {
                        return 0
                      }
                    }).map((token) => (
                      <DogCard id={token.id} name={token.name} image={token.image} />
                    )
                  )}
                </CardLayout>
              )}
            </Row>
          </Container>
          </Web3ReactManager>
        </AppWrapper>
      </>
    );
}

export default App;
