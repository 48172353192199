import React from 'react'
import Card from 'react-bootstrap/Card'

interface DogCardProps {
  id: number
  name: string
  image: string
}

export default function DogCard({id, name, image}: DogCardProps) {
  return (
    <Card key={id} style={{boxShadow: "5px 5px 8px #BABABA"}}>
      <Card.Header
        style={{
          fontFamily: "MontSerrat",
          fontSize: "20px",
          fontWeight: 600,
          textAlign: "center",
          color: "#FFFFFF",
          background: "#DF9A22",
          marginTop: "-5%",
          marginLeft: "10%",
          marginRight: "10%",
          borderRadius: "40px",
          border: "thick solid white",
          zIndex: 10
        }}>
        {name}
      </Card.Header>
      <Card.Img
        src={image}
        style={{
          width: "90%",
          marginTop: "-5%",
          marginBottom: "5%",
          marginLeft: "5%",
          marginRight: "5%"
        }}
        />
    </Card>
  )
}
