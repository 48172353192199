import { Contract } from '@ethersproject/contracts'
import { useActiveWeb3React } from './index'
import { useMemo } from 'react'
import { ZERO_ADDRESS } from '../constants'
import { abi as AvalancheDogsABI } from '../contracts/AvalancheDogs.json'
import { abi as AvalancheDogsSwapABI } from '../contracts/AvalancheDogsSwap.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'

function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library || (address === ZERO_ADDRESS)) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useAvaxDogsContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, AvalancheDogsABI, withSignerIfPossible)
}

export function useAvaxDogsSwapContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, AvalancheDogsSwapABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
