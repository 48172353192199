import React, { useState } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { BigNumber } from '@ethersproject/bignumber'

const ONE_AVAX = BigNumber.from('1000000000000000000')

const ChangeButton = styled.button`
  background: #FF4141;
  font-family: MontSerrat;
  font-style: normal;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  border: none;
  color: #FFFFFF;
`

const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const VerticallyCenteredRed = styled(VerticallyCentered)`
  background: #FF4141;
`

const NumberInput = styled.div`
  background: #FF4141;
  font-family: MontSerrat;
  font-style: normal;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  border: none;
  color: #FFFFFF;
  width: 100px;
`

const MintButton = styled(Button)`
  background: #FFFFFF;
  border-radius: 40px;
  border-width: thick;
  border-color: #FF4141;
  width: 135px;
  &:hover {
    background-color: #818181;
    border-color: #FF4141;
  }
`

const ButtonText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  text-transform: capitalize;
  color: #FF4141;
`

const PriceText = styled.div`
  margin-left: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  color: #FF4141;
`

interface MintBarProps {
  saleStarted?: boolean
  nftPrice: BigNumber
  handleMint: (numToMint: number) => void
}

export default function MintBar({saleStarted = false, nftPrice, handleMint}: MintBarProps) {
  const Max = 10
  const Min = 1
  const [count, setCount] = useState(Min)
  const [ totalPrice, setTotalPrice ] = useState<BigNumber>(nftPrice)

  function handlePlus() {
    if (count < Max) {
      setCount(count + 1)
      setTotalPrice(BigNumber.from(count + 1).mul(nftPrice))
    }
  }

  function handleMinus() {
    if (count > Min) {
      setCount(count - 1);
      setTotalPrice(BigNumber.from(count - 1).mul(nftPrice))
    }
  }

  return (
    <Container>
      <Row>
        <ChangeButton
          style={{ borderRadius: "40px 0px 0px 40px" }}
          onClick={handleMinus}
        >
          -
        </ChangeButton>
        <VerticallyCenteredRed>
          <NumberInput>{count}</NumberInput>
        </VerticallyCenteredRed>
        <ChangeButton
          style={{ borderRadius: "0px 40px 40px 0px" }}
          onClick={handlePlus}
        >
          +
        </ChangeButton>
        <div style={{width: "50px"}}/>
        <MintButton
          disabled={!saleStarted}
          onClick={() => handleMint(count)}>
            <ButtonText>Mint</ButtonText>
        </MintButton>
        <VerticallyCentered>
          <PriceText>
            Total price: {(totalPrice.mul(10).div(ONE_AVAX).toNumber() / 10).toFixed(1)} AVAX
          </PriceText>
        </VerticallyCentered>
      </Row>
    </Container>
  );
}
