import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { BigNumber } from '@ethersproject/bignumber'
import { useActiveWeb3React } from '../../hooks'
import { useAvaxDogsContract, useAvaxDogsSwapContract } from '../../hooks/useContract'
import { AVALANCHE_DOGS_ADDRESS, AVALANCHE_DOGS_SWAP_ADDRESS } from '../../config'

const SwapButton = styled(Button)`
  background: #FF4141;
  border-radius: 40px;
  border-width: thick;
  border-color: #FF1E1E;
  &:hover {
    background-color: #FF4141;
    border-color: #FF4141;
  }
  &:focus {
    background-color: #FF4141;
    border-color: #FF4141;
  }
`

const ButtonText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
`

export const MAX_SWAP_PER_TX = 40

export default function SwapBar() {
  const { account } = useActiveWeb3React()
  const avaxDogsContract = useAvaxDogsContract(AVALANCHE_DOGS_ADDRESS, true)
  const avaxDogsSwapContract = useAvaxDogsSwapContract(AVALANCHE_DOGS_SWAP_ADDRESS, true)
  const [ tokens, setTokens ] = useState<BigNumber[]>([])
  const [ isApproved, setIsApproved ] = useState<boolean>(false)

  useEffect(() => {
    avaxDogsContract?.tokensOfOwner(account)
      .then((response: any) => {
        setTokens(response)
      })
      .catch((error: any) => {
        console.log(error)
      })
    avaxDogsContract?.isApprovedForAll(account, AVALANCHE_DOGS_SWAP_ADDRESS)
      .then((response: any) => {
        setIsApproved(response)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [account, avaxDogsContract])

  useEffect(() => {
    const id = setInterval(() => {
      avaxDogsContract?.isApprovedForAll(account, AVALANCHE_DOGS_SWAP_ADDRESS)
        .then((response: any) => {
          setIsApproved(response)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }, 5000)

    return () => clearInterval(id);
  }, [])

  const handleButton = () => {
    if (isApproved) {
      const tokensToSwap = tokens.length > MAX_SWAP_PER_TX
        ? tokens.slice(0, MAX_SWAP_PER_TX)
        : tokens
      avaxDogsSwapContract?.swapMany(tokensToSwap)
        .then((response: any) => {
        })
        .catch((error: any) => {
          console.log(error)
        })
    } else {
      avaxDogsContract?.setApprovalForAll(AVALANCHE_DOGS_SWAP_ADDRESS, true)
        .then((response: any) => {
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  return (
    <Container>
      <Row style={{justifyContent: "center"}}>
        <SwapButton
          disabled={tokens.length == 0}
          onClick={handleButton}>
            {isApproved && (
              <ButtonText>
                Swap ({tokens.length > MAX_SWAP_PER_TX ? MAX_SWAP_PER_TX : tokens.length})
              </ButtonText>
            )}
            {!isApproved && (
              <ButtonText>Approve</ButtonText>
            )}
        </SwapButton>
      </Row>
    </Container>
  );
}
